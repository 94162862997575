<template>
	<div class="ysm-order-six">
		
			
				<div class="left" align="left">
					<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
					<div>
						<h3>上传口内照片</h3>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;">请在此处上传口内图像。</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;">您可以在<a href="./kn.pdf" target="_blank" style="color: #af9f73;font-weight: bold;text-decoration: underline;">此处</a>找到有关图片的示例。</div>
						<div align="left">
							<el-form :model="ruleForm" :rules="rules"  ref="ruleForm">
								<el-form-item prop="chengxiangDate" label="成像日期">
								  <el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.chengxiangDate" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>		     
								</el-form-item>
							</el-form>
							
						</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">注意</span>：&nbsp;图像需近30天内</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">有效格式</span>：.jpg、.jpeg、.png、.tif、.tiff</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">最小文件大小</span>：500KB</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">最大文件大小</span>：11MB</div>
					</div>
				</div>
				
			
			
				<div class="right">
					<!-- 请上传左侧 -->
					<div class="shanghe"  style="display: inline-block;margin-right: 0.625rem;">
						<div style="position: relative;left: -7.25rem;">左侧<span style="color: red;">*</span></div>
						<el-upload
						  class="upload-demo"
						  drag
						  action=''
						  ref="upload"
						  :http-request="changeFile"
						  multiple
						  style="height: 200px;"
						  >
						  <img v-show="zcVisiable" class="zc" :src="zcPicUrl" style="width: 100%; height: 100%;" />
						  <img src="../../assets/kounei/kouneileft.svg" width="150" height="150" />
						  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						  <div class="el-upload__tip" slot="tip"></div>
						</el-upload>
						
					</div>
					<!-- 请上传右侧 -->
					<div class="xiahe"   style="display: inline-block;">
					<div style="position: relative;left: -7.25rem;">右侧<span style="color: red;">*</span></div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile1"
					  >
					   <img v-show="ycVisiable" class="yc" :src="ycPicUrl" style="width: 100%; height: 100%;" />
					  <img src="../../assets/kounei/kouneiright.svg" width="150" height="150"  />
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<br />
					<!-- 正面 -->
					<div class="yaohe"  style="display: inline-block;margin-right: 0.625rem;">
					<div style="position: relative;left: -7.25rem;">正面<span style="color: red;">*</span></div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile2"
					  >
					  <img v-show="zmVisiable" class="zm" :src="zmPicUrl" style="width: 100%; height: 100%;" />
					    <img src="../../assets/kounei/kouneizheng.svg" width="150" height="150" />
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<!-- 上颌 -->
					<div class="yaohe"   style="display: inline-block;">
					<div style="position: relative;left: -6.25rem;">上传上颌<span style="color: red;">*</span></div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile3"
					  >
					  <img v-show="shVisiable" class="sh" :src="shPicUrl" style="width: 100%; height: 100%;" />
					  <img src="../../assets/kounei/kouneishang.svg" width="150" height="150" />
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<!-- 下颌 -->
					<div class="yaohe"  style="position: relative;left: -8.75rem;">
					<div style="position: relative;left: -6.65rem;">上传下颌<span style="color: red;">*</span></div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile4"
					  >
					   <img v-show="xhVisiable" class="xh" :src="xhPicUrl" style="width: 100%; height: 100%;" />
					   <img src="../../assets/kounei/kouneixia.svg" width="150" height="150" />
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<div align="right">
						<el-button @click="back" class="back"  type="button">返回</el-button>
						<el-button @click="nextStepKN" class="nextStep"  type="button">下一步</el-button>
					</div>
				</div>
		
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				zcVisiable:false,
				ycVisiable:false,
				zmVisiable:false,
				shVisiable:false,
				xhVisiable:false,
				zcPicUrl:'',
				ycPicUrl:'',
				zmPicUrl:'',
				shPicUrl:'',
				xhPicUrl:'',
				ruleForm:{
					chengxiangDate:'',
					oid:'',
				},
				rules:{
					chengxiangDate:[
						{type: 'date', required: true, message: '请选择日期', trigger: 'change'}
					]
				}
				
				
			}
		},
		methods:{
				//上传左侧
			changeFile(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knZuo')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		
						if(res.data.code==200){
							this.zcPicUrl=res.data.data.picUrl;
							this.zcVisiable=true;
							this.$message.success("上传成功");
						}
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//上传右侧
			changeFile1(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knYou')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.ycPicUrl=res.data.data.picUrl;
							this.ycVisiable=true;
						}
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//上传正面
			changeFile2(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knZheng')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.zmPicUrl=res.data.data.picUrl;
							this.zmVisiable=true;
						}
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//上颌
			changeFile3(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knShang')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.shPicUrl=res.data.data.picUrl;
							this.shVisiable=true;
						}
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//下颌
			changeFile4(file){
				console.log('file', file)
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knXia')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.xhPicUrl=res.data.data.picUrl;
							this.xhVisiable=true;
						}
				   	}).catch(err=>{
				   		console.log(err);
				   	});
			},
			//下一步
			nextStepKN(){
				//上传日期
				 let formData1 = new FormData()
				formData1.append('date',this.ruleForm.chengxiangDate)// 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type',"knDate")
			
				this.$axios.post("client/order/uploadResourceDate",formData1,{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					console.log(res);
					if(res.data.code==200){
						this.$message({
							message:"添加成像日期成功",
							type:"success",
							duration:900
						});
						this.$store.commit("setYsmStepIndex",7);
						this.$router.push("/ysmmain/ysmorder/ysmorderseven");
					}
				}).catch(err=>{
					this.$message({
						message:"请联系管理员",
						type:"error",
						duration:900
					});
				});
				
			},
			//返回
			back(){
				this.$store.commit("setYsmStepIndex",5);
				this.$router.push("/ysmmain/ysmorder/ysmorderfive")
			}
		},
		mounted:function(){
			this.$store.commit('setStepIndex', 6);
			//设置默认地址
			sessionStorage.setItem("location","/ysmmain/ysmorder/ysmordersix")
			//获取当前添加的订单oid
			//获取oid
			let oid=sessionStorage.getItem("oid");
			console.log(oid);
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")	
			//设置返回路径
			this.$store.commit("setBackLocation","/ysmmain/ysmorder/ysmorderfive");
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
		
			if(oid!="undefined"&&oid!=''&&oid!=null){
				this.ruleForm.oid=oid;
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					console.log(res.data.data.resource);
					let knDate=res.data.data.resource.knDate;//口内日期
					let zuo=res.data.data.resource.knZuo;//左
					let you=res.data.data.resource.knYou;//右
					let zheng=res.data.data.resource.knZheng;//正
					let shang=res.data.data.resource.knShang;//上
					let xia=res.data.data.resource.knXia;//下
					//日期回显
					if(knDate!=null){
						this.ruleForm.chengxiangDate=knDate;
					}
					//回显左
					if(zuo!=null){
						this.zcVisiable=true;
						document.getElementsByClassName("zc")[0].setAttribute("src",zuo);
						
					}
					//回显右
					if(you!=null){
						this.ycVisiable=true;
						document.getElementsByClassName("yc")[0].setAttribute("src",you);
					}
					//回显正
					if(zheng!=null){
						this.zmVisiable=true;
						document.getElementsByClassName("zm")[0].setAttribute("src",zheng);
					}
					//回显上颌
					if(shang!=null){
						this.shVisiable=true;
						document.getElementsByClassName("sh")[0].setAttribute("src",shang);
					}
					//回显下颌
					if(xia!=null){
						this.xhVisiable=true;
						document.getElementsByClassName("xh")[0].setAttribute("src",xia);
					}
				}).catch(err=>{
					console.log(err);
				});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/ysmmain/ysmorder/ysmorderone");
					}
				});
				
			}
	},
	}
</script>

<style scoped="scoped">
	.ysm-order-six{
		width: 80%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
	}
	
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		/* position: relative;
		left: 1.25rem; */
		width:560px;
	}
	
	.xy{
		display: none;
	}
	/* 按钮 */
	.nextStep{
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}
</style>
